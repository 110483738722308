import * as _depd2 from "depd";

var _depd = "default" in _depd2 ? _depd2.default : _depd2;

import _json2 from "./lib/types/json";
import _raw from "./lib/types/raw";
import _text from "./lib/types/text";
import _urlencoded2 from "./lib/types/urlencoded";
var exports = {};

/**
 * Module dependencies.
 * @private
 */
var deprecate = _depd("body-parser");
/**
 * Cache of loaded parsers.
 * @private
 */


var parsers = Object.create(null);
/**
 * @typedef Parsers
 * @type {function}
 * @property {function} json
 * @property {function} raw
 * @property {function} text
 * @property {function} urlencoded
 */

/**
 * Module exports.
 * @type {Parsers}
 */

exports = exports = deprecate.function(bodyParser, "bodyParser: use individual json/urlencoded middlewares");
/**
 * JSON parser.
 * @public
 */

Object.defineProperty(exports, "json", {
  configurable: true,
  enumerable: true,
  get: createParserGetter("json")
});
/**
 * Raw parser.
 * @public
 */

Object.defineProperty(exports, "raw", {
  configurable: true,
  enumerable: true,
  get: createParserGetter("raw")
});
/**
 * Text parser.
 * @public
 */

Object.defineProperty(exports, "text", {
  configurable: true,
  enumerable: true,
  get: createParserGetter("text")
});
/**
 * URL-encoded parser.
 * @public
 */

Object.defineProperty(exports, "urlencoded", {
  configurable: true,
  enumerable: true,
  get: createParserGetter("urlencoded")
});
/**
 * Create a middleware to parse json and urlencoded bodies.
 *
 * @param {object} [options]
 * @return {function}
 * @deprecated
 * @public
 */

function bodyParser(options) {
  // use default type for parsers
  var opts = Object.create(options || null, {
    type: {
      configurable: true,
      enumerable: true,
      value: undefined,
      writable: true
    }
  });

  var _urlencoded = exports.urlencoded(opts);

  var _json = exports.json(opts);

  return function bodyParser(req, res, next) {
    _json(req, res, function (err) {
      if (err) return next(err);

      _urlencoded(req, res, next);
    });
  };
}
/**
 * Create a getter for loading a parser.
 * @private
 */


function createParserGetter(name) {
  return function get() {
    return loadParser(name);
  };
}
/**
 * Load a parser module.
 * @private
 */


function loadParser(parserName) {
  var parser = parsers[parserName];

  if (parser !== undefined) {
    return parser;
  } // this uses a switch for static require analysis


  switch (parserName) {
    case "json":
      parser = _json2;
      break;

    case "raw":
      parser = _raw;
      break;

    case "text":
      parser = _text;
      break;

    case "urlencoded":
      parser = _urlencoded2;
      break;
  } // store to prevent invoking require()


  return parsers[parserName] = parser;
}

export default exports;