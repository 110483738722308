import * as _bytes2 from "bytes";

var _bytes = "default" in _bytes2 ? _bytes2.default : _bytes2;

import * as _contentType2 from "content-type";

var _contentType = "default" in _contentType2 ? _contentType2.default : _contentType2;

import * as _debug2 from "debug";

var _debug = "default" in _debug2 ? _debug2.default : _debug2;

import _read from "../read";
import * as _typeIs2 from "type-is";

var _typeIs = "default" in _typeIs2 ? _typeIs2.default : _typeIs2;

var exports = {};

/**
 * Module dependencies.
 */
var bytes = _bytes;
var contentType = _contentType;

var debug = _debug("body-parser:text");

var read = _read;
var typeis = _typeIs;
/**
 * Module exports.
 */

exports = text;
/**
 * Create a middleware to parse text bodies.
 *
 * @param {object} [options]
 * @return {function}
 * @api public
 */

function text(options) {
  var opts = options || {};
  var defaultCharset = opts.defaultCharset || "utf-8";
  var inflate = opts.inflate !== false;
  var limit = typeof opts.limit !== "number" ? bytes.parse(opts.limit || "100kb") : opts.limit;
  var type = opts.type || "text/plain";
  var verify = opts.verify || false;

  if (verify !== false && typeof verify !== "function") {
    throw new TypeError("option verify must be function");
  } // create the appropriate type checking function


  var shouldParse = typeof type !== "function" ? typeChecker(type) : type;

  function parse(buf) {
    return buf;
  }

  return function textParser(req, res, next) {
    if (req._body) {
      debug("body already parsed");
      next();
      return;
    }

    req.body = req.body || {}; // skip requests without bodies

    if (!typeis.hasBody(req)) {
      debug("skip empty body");
      next();
      return;
    }

    debug("content-type %j", req.headers["content-type"]); // determine if request should be parsed

    if (!shouldParse(req)) {
      debug("skip parsing");
      next();
      return;
    } // get charset


    var charset = getCharset(req) || defaultCharset; // read

    read(req, res, next, parse, debug, {
      encoding: charset,
      inflate: inflate,
      limit: limit,
      verify: verify
    });
  };
}
/**
 * Get the charset of a request.
 *
 * @param {object} req
 * @api private
 */


function getCharset(req) {
  try {
    return (contentType.parse(req).parameters.charset || "").toLowerCase();
  } catch (e) {
    return undefined;
  }
}
/**
 * Get the simple type checker.
 *
 * @param {string} type
 * @return {function}
 */


function typeChecker(type) {
  return function checkType(req) {
    return Boolean(typeis(req, type));
  };
}

export default exports;